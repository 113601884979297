import { Route, Routes, BrowserRouter } from "react-router-dom";
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import Shop from "./components/shop/Shop";
import Featured from "./components/featured/Featured";
import { Helmet } from "react-helmet";
import "./App.scss";

function App() {


  return (
    <BrowserRouter>

<Helmet>
    <title>Amazon Curated Store</title>
    <meta
      name="description"
      content="Description of the product fro SEO purposes"
    />
    <meta
      name="keywords"
      content="Amazon, Amazon products, Curated, Store, Best Amazon Products, Curated Amazon Products"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:title" content="Product Title - Amazon Curated Store" />
    <meta
      property="og:description"
      content="Description of the product for social sharing"
    />
    <meta property="og:image" content="url-to-thumbnail-image.jpg" />
    <meta property="og:url" content="https://curateclub.co" />
    <meta property="og:type" content="product" />

    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-KJ25M3C1HZ"
    ></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-KJ25M3C1HZ');
      `}
    </script>
  </Helmet>

      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/Featured" element={<Featured />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
