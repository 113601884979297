import { useState } from "react";
import "./Body.scss";
import breville from "../../assets/images/breville.jpg";
import coffee from "../../assets/images/coffee.jpg";
import harvest from "../../assets/images/harvest.jpg";
import mug from "../../assets/images/frankcup.jpg";
import duffel from "../../assets/images/duffel.jpg";
import cloth from "../../assets/images/cloth.jpg";
import yeti from "../../assets/images/yeti.jpg";
import straw from "../../assets/images/straw.jpg";
import jocko from "../../assets/images/jocko.jpg";
import easiyo from "../../assets/images/easiyo.jpg";
import laptop from "../../assets/images/laptop.jpg";
import balance from "../../assets/images/balance.jpg";
import perry from "../../assets/images/perry.jpg";
import keurig from "../../assets/images/keurig.jpg";
import tagine from "../../assets/images/tagine.jpg";
import paxi from "../../assets/images/paxi.jpg";
import fire from "../../assets/images/fire.jpg";
import hannah from "../../assets/images/hannah.jpg";
import shower from "../../assets/images/shower.jpg";
import tablet from "../../assets/images/tablet.jpg";
import ridge from "../../assets/images/ridge.jpg";
import tenoverten from "../../assets/images/tenoverten.jpg";
import kindle from "../../assets/images/kindle.jpg";
import table from "../../assets/images/table.jpg";
import ottoman from "../../assets/images/ottoman.jpg";
import light from "../../assets/images/wakelight.jpg";
import rolltop from "../../assets/images/rolltop.jpg";
import roam from "../../assets/images/roam.jpg";




function Body() {
  const [bannerVisible, setBannerVisible] = useState(true);

  const hideBanner = () => {
    setBannerVisible(false);
  };

 

  return (
    <>
      <div className="body">
        {bannerVisible && (
          <div>
            <h1 className="body__heading">Curate Club is Amazon, curated.</h1>
            <button
              type="button"
              onClick={hideBanner}
              className="body__heading--button"
            >
              x
            </button>
          </div>
        )}
        <div className="body__container">

          {/* Cards */}


          <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3OxFeH7">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$33</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={roam} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Heavy Duty Nylon Dog Collar</p>
            <p className="card__info--by">by Roam</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>

          <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3Sp2jgs">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$134</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={rolltop} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Arcane Roll Top Laptop Backpack</p>
            <p className="card__info--by">by Osprey</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>



          <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48RSM8p">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$171</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={light} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Wake-Up Light Coloured Sunrise Simulation, White</p>
            <p className="card__info--by">by Philips</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>


          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3wei8ik"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$49</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={table} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Rustic Brown & Black End Table 
              </p>
              <p className="card__info--by">by Vasagle</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>



          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3OB8P2u"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$179</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={kindle} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Kindle Essential Bundle 
              </p>
              <p className="card__info--by">by Amazon</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>


          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3Ut156f"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$89</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={ottoman} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Folding Storage Ottoman 43 Inches 
              </p>
              <p className="card__info--by">by SONGMICS</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>


          
          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/47OLMZn"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$799</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={breville} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Breville Barista Express Espresso
              </p>
              <p className="card__info--by">by Breville</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3RBQK5d"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$33</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={coffee} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">Smart Ass, Medium roast 1kg</p>
              <p className="card__info--by">by Kicking Horse Coffee</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3Ni4aSr"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$37</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={harvest} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">Half baked Harvest Every Day</p>
              <p className="card__info--by">by Tieghan Gerard</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/42LqtGq"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$90</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img
                  src={mug}
                  alt="Travel coffee mug"
                  className="card__image--pic"
                />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Ceramic Reusable Cup with Push button Lid 12oz
              </p>
              <p className="card__info--by">by Frank Green</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3uRe2fF"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$139</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={duffel} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                75l White Water Duffel - 100% waterproof
              </p>
              <p className="card__info--by">by Aquaquest</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon card__footer--business"></div>
              <p className="card__footer--trending">Small Business</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3Nl4Oyl"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$16</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img
                  src={cloth}
                  alt="Sustainable dishcloths"
                  className="card__image--pic"
                />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Swedish Dish Cloths, Eco friendly Reusable
              </p>
              <p className="card__info--by">by SUPERSCANDI</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon card__footer--sustainable"></div>
              <p className="card__footer--trending">Sustainable</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3TfYZpJ"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$50</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img
                  src={yeti}
                  alt="Yeti dog bowl 32oz"
                  className="card__image--pic"
                />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                32oz Dog Bowl, Stainless Steel, Non-Slip
              </p>
              <p className="card__info--by">by YETI</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/3uQt2dI"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$24</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img
                  src={straw}
                  alt="LifeStraw personal water filter"
                  className="card__image--pic"
                />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Water Filter for Hiking, Camping and Travel
              </p>
              <p className="card__info--by">by LifeStraw</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/46XDqgW"
              ></a>
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$35</div>
              </div>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={jocko} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                Leadership Strategy and Tactics: Field Manual
              </p>
              <p className="card__info--by">by Jocko Willink</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/41fe7FM"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$104</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={easiyo} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                1kg Red Yogurt Maker with Jar & Instructions
              </p>
              <p className="card__info--by">by EasiYo</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                href="https://amzn.to/486cXyG"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$69</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={laptop} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">
                13" Typhoon Laptop case - 100% Waterproof
              </p>
              <p className="card__info--by">by AquaQuest</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon card__footer--business"></div>
              <p className="card__footer--trending">Small Business</p>
            </div>
          </div>

          <div className="card">
            <div className="card__head">
              <a
                href="https://amzn.to/3RYusMo"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Amazon Link"
                className="card__link"
              >
                <div className="card__button">
                  <div className="card__button--icon"></div>
                  <div className="card__button--price">$60</div>
                </div>
              </a>
            </div>

            <div className="card__body">
              <div className="card__image">
                <img src={balance} alt="" className="card__image--pic" />
              </div>
            </div>

            <div className="card__info">
              <p className="card__info--about">Wooden Balance Board 32 Inch</p>
              <p className="card__info--by">Dream Beauty</p>
            </div>

            <div className="card__footer">
              <div className="card__footer--icon"></div>
              <p className="card__footer--trending">Trending</p>
            </div>
          </div>


          <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48fCh5J">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$26</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={perry} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Friends, Lovers and the Big Terrible Thing: A Memoir</p>
            <p className="card__info--by">by Matthew Perry</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Amazon Charts</p>
          </div>
        </div>

        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48DknKd">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$58</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={keurig} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">K-Mini Single Serve K-Cup Coffee Maker</p>
            <p className="card__info--by">by Keurig</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3GYIYgz">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$114</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={tagine} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Hand Made, Moroccan Tagine Pot</p>
            <p className="card__info--by">by Kamash</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3RDGKbq">
              <div className="card__button">
                <div className="card__button--starr"></div>
                <div className="card__button--price">$105</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={paxi} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Luzon Daypack 24l</p>
            <p className="card__info--by">by Cotopaxi</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/4azI1sL">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$90</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={fire} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Tabletop Fire Pit</p>
            <p className="card__info--by">by Housewise</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48x4ViH">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$45</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={hannah} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Tropical Green Leaf Artwork</p>
            <p className="card__info--by">by ArtbyHannah</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48et1is">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$30</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={shower} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Shower Caddy 5-Pack</p>
            <p className="card__info--by">by Coraje</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48et1is">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$195</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={tablet} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Fire HD 10 Kids Pro Tablet</p>
            <p className="card__info--by">by Amazon</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3veVUwd">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$130</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={ridge} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Minimalist Wallet for Men</p>
            <p className="card__info--by">by Ridge Wallet</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3TBS4HK">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$19</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={tenoverten} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">The Eco File, Non-Toxic Nail Care</p>
            <p className="card__info--by">by Tenoverten</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>


        </div>
      </div>
    </>
  );
}

export default Body;
