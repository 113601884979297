import "./Featured.scss";
import mvmt from "../../assets/images/mvmt.jpg";
import vedana from "../../assets/images/vedana.jpg";
import mushroom from "../../assets/images/mushroom.jpg";
import dakine from "../../assets/images/dakine.jpg";
import compost from "../../assets/images/compost.jpg";
import basket from "../../assets/images/basket.jpg";
import oil from "../../assets/images/oil.jpg";
import cooler from "../../assets/images/cooler.jpg";
import coffee from "../../assets/images/49th.jpg";
import wallpaper from "../../assets/images/wallpaper.jpg";
import light from "../../assets/images/wakelight.jpg";
import jetboil from "../../assets/images/jetboil.jpg";
import haden from "../../assets/images/haden.jpg";
import bookrack from "../../assets/images/bookrack.jpg";


function Featured() {
    return(
        <>
        <div className="featured">
            <h1 className="featured__heading">Featured</h1>
            <div className="featured__container">

                {/* Cards */}


                <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/495kRcv">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$129</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={haden} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Retro Stainless Steel Kettle</p>
            <p className="card__info--by">by Haden Dorset</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>


        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3unELAw">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$54</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={bookrack} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Recycled Fabric Kids Book Rack Organizer</p>
            <p className="card__info--by">by 3 Sprouts</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>




                <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3UrXDcj">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$125</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={jetboil} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Jetboil Zip Cooking System</p>
            <p className="card__info--by">by Jetboil</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>


            <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3vUBg4R">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$109</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={mvmt} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">MVMT Classic Flagship Leather Watch</p>
            <p className="card__info--by">by MVMT</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>

        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/4blUIIb">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$40</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={vedana} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Ayurvedic Pure Copper Water Bottle</p>
            <p className="card__info--by">by Vedana</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/42gJpgh">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$45</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={mushroom} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Whole-body Harmony 5 Mushroom Powder blend</p>
            <p className="card__info--by">by Harmonic Arts</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3OrKVGD">
              <div className="card__button">
                <div className="card__button--starr"></div>
                <div className="card__button--price">$303</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={dakine} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Woodland Floral 110l Split Roller</p>
            <p className="card__info--by">by Dakine</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3HJVOzR">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$69</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={compost} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Bamboo Kitchen Compost bin</p>
            <p className="card__info--by">by Banboozle</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3w1Rzgv">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$32</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={basket} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Large Storage Basket</p>
            <p className="card__info--by">by MicroIdeas</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3SJTOxB">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$149</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={oil} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Ceramic Ultrasonic Essential Oil Diffuser</p>
            <p className="card__info--by">by Vitruvi</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/42uTuq2">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$171</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={cooler} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Retro Vintage Metal Cooler</p>
            <p className="card__info--by">by Foster & Rye</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/3Ie9a7O">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$19</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={coffee} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Old School Espresso 12 Ounce</p>
            <p className="card__info--by">49th Parallel Coffee Roasters</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>
        
        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/438FmTL">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$91</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={wallpaper} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Tropical Wallpaper, Peel & Stick</p>
            <p className="card__info--by">by Cliour</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>

        <div className="card">
          <div className="card__head">
            <a className="card__link" target="_blank" rel="noopener noreferrer" aria-label='Amazon Link' href="https://amzn.to/48RSM8p">
              <div className="card__button">
                <div className="card__button--icon"></div>
                <div className="card__button--price">$171</div>
              </div>
            </a>
          </div>

          <div className="card__body">
            <div className="card__image">
              <img src={light} alt="" className="card__image--pic" />
            </div>
          </div>

          <div className="card__info">
            <p className="card__info--about">Wake-Up Light Coloured Sunrise Simulation, White</p>
            <p className="card__info--by">by Philips</p>
          </div>

          <div className="card__footer">
            <div className="card__footer--starr"></div>
            <p className="card__footer--trending">Featured</p>
          </div>
        </div>


            </div>
        </div>
        </>
    )
}

export default Featured;