import "./Footer.scss";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer__left">
          <h2 className="footer__left--heading">
            Discover great Amazon products, curated with great taste
          </h2>
          <p className="footer__left--about">
            Curate Club is an independent company.{" "}
            <a
              href={`https://www.amazon.ca`}
              className="footer__amazon"
              target="blank"
              rel="noopener noreferrer"
            >
              Amazon
            </a>{" "}
            is a registered trademark. &copy; 2023 CurateClub.co All rights
            reserved{" "}
            
          </p>
          <br />
          <p className="footer__left--terms">This website contains affiliate links. If you make a purchase
            through these links, CurateClub.co may earn a small commission
            at no additional cost to you. This commission helps support and
            maintain the site, allowing us to continue providing valuable
            content. Thank you for your support!
            </p>
        
          <div className="socials">
            <a
              href={`https://www.instagram.com/curateclub.co`}
              className="socials__insta socials__icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Social media link"
            ></a>
            <a
              href={`https://www.pinterest.ca/curateclub`}
              className="socials__pinterest socials__icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Social media link"
            ></a>

            <a href="mailto:info@curateclub.co" className="socials__email">info@curateclub.co</a>
          </div>
        </div>
        <div className="footer__right"></div>
      </div>
    </>
  );
}

export default Footer;
