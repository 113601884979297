import { useState } from "react";
import "./Modal.scss";
import emailjs from "@emailjs/browser";

function Modal({ closeModal }) {
  const [ showModal ] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_PUBLIC_KEY,
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );

    closeModal();
  };


 

  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal__container">
        <div className="modal__content">
          <span onClick={closeModal} className="modal__close">
            &times;
          </span>
          <h3 className="modal__heading">
            Unlock a world of curated treasures! Join our exclusive monthly
            curation for Amazon's finest finds - designed for your taste!
          </h3>
          <div className="modal__form">
            <form onSubmit={handleSubmit}>
              <div>
                <h4 className="modal__sign">Sign Up</h4>
                <label className="modal__label">
                  <input
                    type="text"
                    name="name"
                    className="modal__input"
                    placeholder="Name*"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div>
                <label className="modal__label">
                  <input
                    type="email"
                    name="email"
                    className="modal__input"
                    placeholder="Email*"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div>
                <button type="submit" className="modal__button">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
