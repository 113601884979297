import { Link } from "react-router-dom";
import "./Header.scss";
import Modal from "../modal/Modal";
import { useState, useEffect, useRef } from "react";

function Header() {

  const [isOpen, setIsOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const dropdownRef = useRef();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const scrollThreshold = 100;
    setShowNav(scrollY <= scrollThreshold);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

 
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
}, [isOpen]);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="nav"
              style={{ opacity: showNav ? 1 : 0, transition: "opacity 0.5s" }}

      >
        <div className="hamburger" onClick={handleToggle}>
          <span></span>
        </div>

        <ul ref={dropdownRef} className={`nav__list ${isOpen ? "open" : ""}`}>
          <li className="nav__item">
            {" "}
            <div className="nav__home--icon nav__icons"></div>
            <Link className="nav__link" to="/">
              Home
            </Link>
          </li>

          <li className="nav__item">
            {" "}              
            <div className="nav__featured--icon nav__icons"></div>
            <Link className="nav__link" to="/Featured">
              Featured
            </Link>
          </li>

          {/* </div> */}
          {/* <div className="header__nav--shop header__nav--container">
            <div className="header__nav--shop-icon header__nav--icon"></div>
            <Link className="header__nav--link" to="/Shop">
              <h3 className="header__nav--text">Shop</h3>
            </Link> */}
          {/* </div> */}
        </ul>

        <div className="nav__icon">
          <div className="nav__icon--icon"></div>
        </div>

        <button type="button" onClick={openModal} className="nav__join">
          Join
        </button>
        {showModal && <Modal closeModal={closeModal} />}
      </div>
    </>
  );
}

export default Header;
