import "./Shop.scss";
import everyday from "../../assets/images/everydaycategory.jpg";
import her from "../../assets/images/hercategory.jpg";
import his from "../../assets/images/hiscategory.jpg";
import kids from "../../assets/images/kidscategory.jpg";
import bath from "../../assets/images/bathcategory.jpg";
import art from "../../assets/images/artcategory.jpg";
import pets from "../../assets/images/petcategory.jpg";
import outdoor from "../../assets/images/outdoorcategory.jpg";
import backpacks from "../../assets/images/backpackcategory.jpg";
import kitchen from "../../assets/images/kitchencategory.jpg";
import home from "../../assets/images/homewarecategory.jpg";
import books from "../../assets/images/bookcategory.jpg";

function Shop() {
    return(
        <>
        <div className="shop">

                <div className="shop__card">
                    <img src={books} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Books</h4>
                </div>

                <div className="shop__card">
                    <img src={home} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Homeware</h4>
                </div>

                <div className="shop__card">
                    <img src={kitchen} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Kitchen</h4>
                </div>

                <div className="shop__card">
                    <img src={backpacks} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Backpacks</h4>
                </div>

                <div className="shop__card">
                    <img src={outdoor} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Outdoors</h4>
                </div>

                <div className="shop__card">
                    <img src={pets} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Pets</h4>
                </div>

                <div className="shop__card">
                    <img src={art} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Art</h4>
                </div>

                <div className="shop__card">
                    <img src={bath} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Bathroom</h4>
                </div>

                <div className="shop__card">
                    <img src={kids} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Kids</h4>
                </div>

                <div className="shop__card">
                    <img src={his} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">His</h4>
                </div>

                <div className="shop__card">
                    <img src={her} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Hers</h4>
                </div>

                <div className="shop__card">
                    <img src={everyday} alt="" className="shop__card--image" />
                    <h4 className="shop__card--title">Everyday</h4>
                </div>

        </div>
        </>
    )
}

export default Shop;
